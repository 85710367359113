import React from "react"
import Article from "./Articles"
import ReactGA from "react-ga"

function trackDownload() {
  ReactGA.event({
    category: "MensGuide.pdf",
    action: "Download",
    label: "3.0"
  })
}

export default function Guide({ articleTimeout, current, onCloseArticle }) {
  return (
    <Article
      id={"guide"}
      current={current}
      articleTimeout={articleTimeout}
      onCloseArticle={onCloseArticle}
    >
      <h5 className="major">The Guide</h5>
      <h2>Start Here</h2>
      <p>
        If deeper connection among men sounds alluring
        <br />
        If the Principles ring true
        <br />
        If you want this
        <br />
      </p>
      <p>
        The guide below offers a script, key concepts, and the knowledge we have
        on holding a group of men.
      </p>

      <a
        style={{ float: "right" }}
        href="/mens-group-guide3.pdf"
        target="_blank"
        rel="noopener noreferrer"
        onClick={trackDownload}
      >
        Get The Guide
      </a>
    </Article>
  )
}
