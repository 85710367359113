import React from "react"
import Article from "./Articles"

export default function Principles({
  articleTimeout,
  current,
  onCloseArticle
}) {
  return (
    <Article
      id={"principles"}
      current={current}
      articleTimeout={articleTimeout}
      onCloseArticle={onCloseArticle}
    >
      <h5 className="major">The Foundation</h5>
      <h2>Principles</h2>
      <p>Our foundation is built on the following principles</p>
      <ol>
        <li>That our conditioning is isolation yet we yearn for connection</li>
        <li>
          That our thinking has been small and safe and that has distorted our
          reality
        </li>
        <li>
          That we tend towards consumption yet through service we are free
        </li>
      </ol>
      <p>
        This group is open to all who identify as men who desire to live by
        these principles, and follow the rules of play.
      </p>
      <br />
      <h2>Rules of Play</h2>
      <ol>
        <li>
          <strong>Come as a full adult</strong> — you enter this loose
          association freely and are responsible for your actions while here.
          You consent to potentially hearing things that may trigger your auto
          psychic defense systems.
        </li>
        <li>
          <strong>Say the vulnerable thing</strong> — the more you give up the
          more you will get out of coming. This does not mean saying more but
          rather saying the potent part which is often the uncomfortable part.
        </li>
        <li>
          <strong>Leave competition at the door</strong> — as men we have a
          tendency to default to competition. Competition is not bad - like
          anything it has a purpose. In the context of this group we have found
          it to be counterproductive.
        </li>
        <li>
          <strong>Do not fix each other</strong> — we are here to bear witness
          to each others’ growth, not to do it for them. The thing to say to
          another man in the group that is most beneficial is not the answer to
          his problem.
        </li>
        <li>
          <strong>Confidentiality</strong> — who you see and what you see here
          stays here. Also you are responsible for any information or names that
          you bring into the group. Do so with responsibility and care.
        </li>
      </ol>
    </Article>
  )
}
