import React from "react"
import PropTypes from "prop-types"

const Header = props => (
  <header id="header" style={props.timeout ? { display: "none" } : {}}>
    <div className="logo">
      <span className="icon fa-handshake-o" />
    </div>
    <div className="content">
      <div className="inner">
        <h1>Men's Group</h1>
        <h3>A Framework for Connection Among Men</h3>
      </div>
    </div>
    <nav>
      <ul>
        {[
          { title: "The Mission", onPress: () => props.onOpenArticle("about") },
          {
            title: "The Foundation",
            onPress: () => props.onOpenArticle("principles")
          },
          {
            title: "The Guide",
            onPress: () => props.onOpenArticle("guide")
          }
        ].map(({ title, onPress }) => {
          return (
            <li key={title}>
              <a href="javascript:;" onClick={onPress}>
                {title}
              </a>
            </li>
          )
        })}
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool
}

export default Header
