import React from "react"

export default function Article({
  id,
  articleTimeout,
  current,
  onCloseArticle,
  children
}) {
  return (
    <article
      id={id}
      className={`${current === id ? "active" : ""} ${
        articleTimeout ? "timeout" : ""
      }`}
      style={{ display: "none" }}
    >
      {children}
      <div className="close" onClick={onCloseArticle} />
    </article>
  )
}
