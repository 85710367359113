import React from "react"
import Article from "./Articles"
// import pic03 from "../images/men.jpg"

export default function About({ articleTimeout, current, onCloseArticle }) {
  return (
    <Article
      id={"about"}
      current={current}
      articleTimeout={articleTimeout}
      onCloseArticle={onCloseArticle}
    >
      <h5 className="major">The Mission</h5>
      {/* <span className="image main">
        <img src={pic03} alt="" />
      </span> */}
      <h2>About</h2>
      <p>
        Welcome to Men's Group. We gather here to practice openness and radical
        candor, and seek reflection. Our intention is to use the bonds of
        brotherhood to wake ourselves out of our conditioned patterns so that we
        may be our truest selves. This is no easy path. The work we choose to do
        here is uncomfortable and may bring up feelings that may disrupt our
        homeostasis. This is intentional and purposeful. When we gather in
        connection to practice this level of self-honesty and responsibility we
        have found that our lives are fuller and our relationships are more
        fulfilling.
      </p>
    </Article>
  )
}
