import React from "react"
import PropTypes from "prop-types"
import Principles from "./Principles"
import About from "./About"
import Guide from "./Guide"
class Main extends React.Component {
  render() {
    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: "flex" } : { display: "none" }}
      >
        <About
          onCloseArticle={this.props.onCloseArticle}
          current={this.props.article}
          articleTimeout={this.props.articleTimeout}
        />
        <Principles
          onCloseArticle={this.props.onCloseArticle}
          current={this.props.article}
          articleTimeout={this.props.articleTimeout}
        />
        <Guide
          onCloseArticle={this.props.onCloseArticle}
          current={this.props.article}
          articleTimeout={this.props.articleTimeout}
        />
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired
}

export default Main
